<template>
  <div>
    <!--TITULOS  -->
    <div class="air__utils__heading"> <h5>{{equipmentType}} </h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <hr class="bg-primary">
    <div class="card-container">
      <a-tabs :animated="false" type="card">
        <a-tab-pane key="1" :tab="equipmentType">
          <h2 class="text-white text-center bg-primary bordered rounded mt-2 py-2">{{equipmentType}}</h2>
          <auxiliarMasters :auxiliar_master_type="equipmentType"/>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="`Checks para ${equipmentType}`">
          <h2 class="text-white text-center bg-primary bordered rounded mt-2 py-2">{{`Checks para ${equipmentType}`}}</h2>
          <checksEquipmentSources :equipment_type="equipmentType"/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import auxiliarMasters from '@/views/geocycle/components/auxiliarMasters'
import checksEquipmentSources from '@/views/geocycle/components/checksEquipmentSources'
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'operationEquipments',
  mixins: [fractalMixin],
  components: {
    auxiliarMasters,
    checksEquipmentSources,
  },
  data() {
    return {
      // Tipo de Equipo
      equipmentType: this.$route.params.type,
      // Constantes
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Operaciones',
          name: 'operations',
        },
        {
          breadcrumbName: 'Control de Operaciones',
          name: 'operationControl',
        },
        {
          breadcrumbName: `Administración de ${this.$route.params.type}`,
        },
      ],
      // Constantes
    }
  },
}
</script>
<style scoped>
.card-container {
  background: #f5f5f5;
  overflow: hidden;
  padding: 8px;
}
.card-container > .ant-tabs-card > .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
</style>
